import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmConsult, PatientDetails, Session } from "../models";
import { Button } from "./Common/Button";
import { ConsultTypesMap, InitialConfirmConsult } from "../constants";
import {
  FormatName,
  isValidEmail,
  isValidMobileNo,
  sendGAEvent
} from "../helpers";
import { InputControl } from "./Common";

interface Props {
  session: Session;
  patient: PatientDetails;
  handleNextClick: Function;
  saveDetailsChangesClick: Function;
}
const ConfirmConsultDetails = ({
  session,
  patient,
  handleNextClick,
  saveDetailsChangesClick
}: Props) => {
  const [confirmConsult, setConfirmConsult] = useState<ConfirmConsult>(
    InitialConfirmConsult
  );
  const [isConfirmAndPayButtonActive, setIsConfirmAndPayButtonButtonActive] =
    useState(false);
  const [isSaveChangesButtonActive, setIsSaveChangesButtonButtonActive] =
    useState(false);
  const [displayEditDetails, setDisplayEditDetails] = useState(false);
  const requiredProps = useMemo(
    () => ["agreeTCs", "consent", "acknowledge"],
    []
  );
  const editRequiredProps = useMemo(
    () => ["name", "mobileNo", "email", "consultType"],
    []
  );
  const [focusName, setFocusName] = useState("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleChange = (e: any): void => {
    const { name, value, checked } = e.target;
    setConfirmConsult(value);
    let newValue = ["agreeTCs", "consent", "acknowledge"].includes(name)
      ? checked
      : value;

    const newConfirmConsult = {
      ...confirmConsult,
      [name]: newValue
    };

    setConfirmConsult(newConfirmConsult);
    confirmAndPayButtonIsActive(newConfirmConsult);
  };

  const confirmAndPayButtonIsActive = useCallback(
    (confirmConsult: ConfirmConsult): void => {
      let isButtonActive = true;
      const map = new Map(Object.entries(confirmConsult));

      requiredProps.forEach((prop) => {
        if (!map.get(prop)) {
          isButtonActive = false;
        }
      });
      setIsConfirmAndPayButtonButtonActive(isButtonActive);
    },
    [requiredProps]
  );

  const saveChangesButtonIsActive = useCallback(
    (confirmConsult: ConfirmConsult): void => {
      let isButtonActive = true;
      const map = new Map(Object.entries(confirmConsult));

      editRequiredProps.forEach((prop) => {
        if (!map.get(prop)) {
          isButtonActive = false;
        }
        if (prop === "mobileNo" && !isValidMobileNo(map.get(prop))) {
          isButtonActive = false;
        }
        if (prop === "email" && !isValidEmail(map.get(prop))) {
          isButtonActive = false;
        }
      });
      setIsSaveChangesButtonButtonActive(isButtonActive);
    },
    [editRequiredProps]
  );

  const validate = () => {
    let errorsExist = false;

    const validationErrors: string[] = [];
    let focusName = "";
    const addError = (fieldName: string) => {
      validationErrors.push(fieldName);
      errorsExist = true;
      if (!focusName) {
        focusName = fieldName;
      }
    };

    const map = new Map(Object.entries(confirmConsult));
    if (displayEditDetails) {
      editRequiredProps.forEach((prop) => {
        if (!map.get(prop)) {
          addError(prop);
        }
        if (prop === "mobileNo" && !isValidMobileNo(map.get(prop))) {
          addError(prop);
        }
        if (prop === "email" && !isValidEmail(map.get(prop))) {
          addError(prop);
        }
      });
    } else {
      requiredProps.forEach((prop) => {
        if (!map.get(prop)) {
          addError(prop);
        }
      });
    }

    if (!errorsExist) {
      if (displayEditDetails) {
        setDisplayEditDetails(false);
        saveDetailsChangesClick(confirmConsult);
        confirmAndPayButtonIsActive(confirmConsult);
      } else {
        sendGAEvent({
          event: "confirm_details",
          step: 12,
          step_name: "Confirm Details",
          user_id: patient.maskedEmail
        });
        handleNextClick({
          ...confirmConsult
        });
      }
    } else {
      setValidationErrors(validationErrors);
      setFocusName(focusName);
    }
  };

  const handleEditDetailsClick = () => {
    setDisplayEditDetails(true);
    saveChangesButtonIsActive(confirmConsult);
  };

  const selectConsultTypeClick = (consultName: string) => {
    const consultType = ConsultTypesMap.get(consultName);
    const newConfirmConsult = {
      ...confirmConsult,
      consultType: consultType?.name || "",
      consultLabel: `${consultType?.label} ($${consultType?.price})`,
      price: consultType?.price || 0
    };
    setConfirmConsult(newConfirmConsult);
    saveChangesButtonIsActive(newConfirmConsult);
  };

  const cancelEditChanges = () => {
    const newConfirmConsult = createInititalConfirmConsult(session, patient);
    setConfirmConsult(newConfirmConsult);
    setDisplayEditDetails(false);
    confirmAndPayButtonIsActive(newConfirmConsult);
  };

  useEffect(() => {
    if (!session.consult || !patient) {
      return;
    }
    const newConfirmConsult = createInititalConfirmConsult(session, patient);
    setConfirmConsult(newConfirmConsult);
    confirmAndPayButtonIsActive(newConfirmConsult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, patient]);

  const createInititalConfirmConsult = (
    session: Session,
    patient: PatientDetails
  ) => {
    return {
      consultType: session.consult?.name || "",
      consultLabel: `${session.consult?.label} ($${session.consult?.price})`,
      price: session.consult?.price || 0,
      agreeTCs: session.agreeTCs,
      consent: session.consent,
      acknowledge: session.acknowledge,
      name: FormatName(patient),
      mobileNo: session.contactNumber,
      email: session.email
    };
  };
  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step summary col-xs-11">
          <h3 className="cs-chkin-form-step-heading">
            Review and confirm consult details
          </h3>

          {!displayEditDetails && (
            <>
              <div className="dark-content-box" id="summary_details">
                <div style={{ width: "100%" }}>
                  <div className="mb-4">
                    <div className="sub-heading">For:</div>
                    <div className="summary-information" id="summary_name">
                      {confirmConsult.name}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="sub-heading">Phone number:</div>
                    <div className="summary-information" id="summary_mobile">
                      {confirmConsult.mobileNo}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="sub-heading">Email address:</div>
                    <div className="summary-information" id="summary_email">
                      {confirmConsult.email}
                    </div>
                  </div>

                  <div>
                    <div className="sub-heading">Consult type:</div>
                    <div
                      className="summary-information"
                      id="summary_consult_type"
                    >
                      {confirmConsult.consultLabel}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group mb-0">
                <div className="form-inline rowGap-2" id="consent_form_group">
                  <label className="checkbox-container pt-2" id="agree_group">
                    <small>
                      I agree to the{" "}
                      <a
                        href="https://www.oursage.com.au/terms-of-use"
                        target="_blank"
                        rel="noreferrer"
                      >
                        T&Cs
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.oursage.com.au/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </small>
                    <input
                      type="checkbox"
                      id="agreeTCs"
                      name="agreeTCs"
                      checked={confirmConsult.agreeTCs}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="checkbox-container pt-2" id="consent_group">
                    <small>
                      I consent to a telehealth consult with a general
                      practitioner. I understand they will attempt to contact me
                      3 times and if unsuccessful there will be no refund
                      available.
                    </small>
                    <input
                      type="checkbox"
                      id="consent"
                      name="consent"
                      checked={confirmConsult.consent}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label
                    className="checkbox-container pt-2"
                    id="acknowledge_group"
                  >
                    <small>
                      I acknowledge that a Medicare rebate will not be available
                      for this telehealth consult.
                    </small>
                    <input
                      type="checkbox"
                      id="acknowledge"
                      name="acknowledge"
                      checked={confirmConsult.acknowledge}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div className="mb-2">
                  <button
                    className="btn btn-lg btn-primary js-btn-prev d-none"
                    type="button"
                  ></button>
                  <Button
                    label={`Confirm and pay $${confirmConsult?.price}`}
                    handleClick={validate}
                    isLoading={false}
                    isActive={isConfirmAndPayButtonActive}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-lg btn-block btn-outline btn-edit-review-information"
                    type="button"
                    id="edit_details"
                    name="edit_details"
                    onClick={handleEditDetailsClick}
                  >
                    Edit details
                  </button>
                </div>
              </div>
            </>
          )}

          {displayEditDetails && (
            <>
              <div id="edit_summary_details">
                <div className="col-sm-12 form-group float-label-control">
                  <label htmlFor="edit_name">For:</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="edit_name"
                    name="edit_name"
                    value={FormatName(patient)}
                  />
                </div>

                <InputControl
                  type="text"
                  label="Mobile number*"
                  name="mobileNo"
                  value={confirmConsult.mobileNo}
                  onChange={handleChange}
                  focus={focusName === "mobileNo"}
                  error={validationErrors.includes("mobileNo")}
                />

                <InputControl
                  type="text"
                  label="Email address*"
                  name="email"
                  value={confirmConsult.email}
                  onChange={handleChange}
                  focus={focusName === "email"}
                  error={validationErrors.includes("email")}
                />

                <h3 className="cs-chkin-form-step-heading">
                  Select a consult type
                </h3>
                <div id="regular_consult_options">
                  <label
                    htmlFor="standard_consult"
                    className="cursor-pointer d-block"
                    style={{ marginBottom: 32 }}
                  >
                    <span className="radio-consult-type d-flex">
                      <input
                        id="standard_consult"
                        type="radio"
                        name="consult"
                        data-req="true"
                        className="valid"
                        data-type="standard"
                        value="standard"
                        defaultChecked={
                          "standard" === confirmConsult.consultType
                        }
                        onClick={() => selectConsultTypeClick("standard")}
                      />
                      <label
                        id="standard_consult_label"
                        htmlFor="standard_consult"
                      >
                        Standard telehealth consult ($
                        <span className="standard">
                          {session.standardPrice}
                        </span>
                        )
                      </label>
                    </span>

                    <span className="description d-block">
                      Up to 10 minutes in length. Please note that if a short
                      consult goes longer than 10 minutes, you will be charged
                      an extra $10.
                    </span>
                  </label>

                  <label
                    htmlFor="priority_consult"
                    className="cursor-pointer d-block"
                    style={{ marginBottom: 24 }}
                  >
                    <span className="radio-consult-type d-flex">
                      <input
                        id="priority_consult"
                        type="radio"
                        name="consult"
                        data-req="true"
                        className="valid"
                        data-type="priority"
                        value="priority"
                        defaultChecked={
                          "priority" === confirmConsult.consultType
                        }
                        onClick={() => selectConsultTypeClick("priority")}
                      />
                      <label
                        id="priority_consult_label"
                        htmlFor="priority_consult"
                      >
                        Priority consult ($
                        <span className="priority">
                          {session.priorityPrice}
                        </span>
                        )
                      </label>
                    </span>

                    <span className="description d-block">
                      Get ahead of the queue to minimise waiting time.
                    </span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="mb-2">
                  <button
                    className="btn btn-lg btn-primary js-btn-prev d-none"
                    type="button"
                  ></button>
                  <Button
                    label="Save changes"
                    handleClick={validate}
                    isLoading={false}
                    isActive={isSaveChangesButtonActive}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-lg btn-block btn-outline btn-edit-review-information"
                    type="button"
                    id="edit_details"
                    name="edit_details"
                    onClick={cancelEditChanges}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmConsultDetails;
